/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bec4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}